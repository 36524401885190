$main: rgb(90, 152, 247);
$white: #ffffff;
$black: #000000;
$linear: linear-gradient(350.54deg, #5a98f7 0%, #1658f3 100%);
$oxford-blue: #354052;
$ribbon-blue: #1658f3;
$comet-blue: #616581;
$persimmon-red: #ff624c;
$athens-gray: #ebebef;
$fiord-blue: #3a3f62;
$spun-pearl-grey: #B0B2C0;
$mine-shaft-black: #333;
$malachite-green: #11c314;
