.loading {
  position: relative;
}

.chat__bubble-typing {
  margin-left: 8px;
}

.loading .dot {
  height: 4px;
  width: 4px;
  border-radius: 5px;
  display: block;
  float: left;
  margin: 0 0 0 4px;
}

.loading .dot:first-child {
  margin: 0;
}

.loading .dot.one {
  -webkit-animation: cycleOne 1s ease-in-out infinite;
  -webkit-animation-direction: normal;
}

.loading .dot.two {
  -webkit-animation: cycleTwo 1s ease-in-out infinite;
  -webkit-animation-direction: normal;
}

.loading .dot.three {
  -webkit-animation: cycleThree 1s ease-in-out infinite;
  -webkit-animation-direction: normal;
}

.typingContainer {
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-bottom: 16px;
}

.typingLogo {
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 100%;
}

@keyframes cycleOne {
  0% {
    background: var(--mutedActionColor);
  }
  33.333% {
    background: var(--actionColor);
  }
  66.6667% {
    background: var(--mutedActionColor);
  }
  100% {
    background: var(--mutedActionColor);
  }
}

@keyframes cycleTwo {
  0% {
    background: var(--mutedActionColor);
  }
  33.333% {
    background: var(--mutedActionColor);
  }
  66.6667% {
    background: var(--actionColor);
  }
  100% {
    background: var(--mutedActionColor);
  }
}

@keyframes cycleThree {
  0% {
    background: var(--mutedActionColor);
  }
  33.333% {
    background: var(--mutedActionColor);
  }
  66.6667% {
    background: var(--mutedActionColor);
  }
  100% {
    background: var(--actionColor);
  }
}
