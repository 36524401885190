.linkEdited,
.linkCreated {
  cursor: pointer;
  color: #0000ee;
  text-decoration: underline;
}

.linkEdited:visited,
.linkCreated:visited {
  color: #0000ee;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: rotate(-45deg);
  }

  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
