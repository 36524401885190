@import 'src/variables.scss';

.wrapper {
  position: relative;
}

.container {
  box-shadow: 0px 4px 29px rgba(19, 69, 186, 0.0855129);
  border-radius: 10px;
}

.button {
  background: $athens-gray;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 37px;
  align-items: center;
  display: flex;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.bubbleContainer {
  display: flex;
  flex-direction: column;
  padding: 8px;
  padding-top: 0;
  padding-right: 0;
}

.bubble {
  animation-name: messageAnimation;
  animation-duration: 0.5s;
  clear: both;
  box-sizing: border-box;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 14px;
  line-height: 18px;
  max-width: 100%;
}

.bubble a {
  color: var(--linkColor) !important;
}

.bubbleReceived {
  float: left;
  font-size: 14px;
}

.bubbleSent {
  float: right;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.gptBlinkingCursor {
  display: inline-block;
  width: 8px;
  height: 16px;
  margin-bottom: -2px;
  animation: blink 0.5s infinite;
}
