@import 'src/variables.scss';

.userContainer {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-start;
  white-space: pre-wrap;
  padding-right: 24px;
  padding-left: 24px;
  margin: 0 0 24px 0;

  &:hover [data-id="reaction-bar"], &:active [data-id="reaction-bar"] {
    display: flex;
  }
}

.assistantContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  white-space: pre-wrap;
  padding-left: 55px;
  margin: 0 0 24px 0;
  position: relative;
}

.containerWithLogo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space: pre-wrap;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 0 24px 0;
  position: relative;

  &:first-child {
    margin-top: 4px;
    margin-bottom: 16px;
  }
}

div.marginWithQR {
  margin-bottom: 0px !important;
}

.logo {
  height: 24px;
  width: 24px;
  cursor: pointer;
  animation-duration: 0.3s;
  animation-name: appear;
  margin: 0;
  border-radius: 50%;
}

.msgWrapper {
  display: flex;
  justify-content: flex-start;
  white-space: pre-wrap;
}

.userMsgWrapper {
  flex-direction: row-reverse;
}