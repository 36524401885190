@import "src/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  animation-duration: 0.1s;
  animation-name: appearance;
  overflow: hidden;
  float: right;
  opacity: 0.99;
  background-color: $white;
  border-radius: 10px;
  position: relative;
}
