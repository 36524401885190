@import 'src/variables.scss';
input {
  background: $white;
  width: 100%;
  height: 58px;
  border: 0;
  box-sizing: border-box;
  font-size: 14px !important;
  padding: 10px 24px;
  border-radius: 10px;
  &:focus {
    outline: 0;
    touch-action: none;
  }
  color: $mine-shaft-black;
}

.wrap {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.4s ease-in-out all;
}

.disclaimer {
  overflow: hidden;
  margin: 8px 24px 0 24px;
  height: 32px;
  color: #1c2024;
  font-size: 10px;
  text-align: center;

  p {
    margin: 0;
  }

  a {
    color: #1c2024;
  }
}

.footerWatermark {
  text-align: center;
  font-size: 10px;
  display: block;
  height: 16px;
  color: #1c2024;
  text-decoration: none;
  margin-top: 8px;
  width: max-content;
  align-self: center;
}
.mobileMargin {
  margin-bottom: 12px;
}
.chromeWrap {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
}
.msgWrapChrome {
  overflow-y: scroll;
}
.msgWrap {
  height: 420px;
  box-sizing: border-box;
  padding-bottom: 20px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.disabled {
  font-size: 12px;
  color: $fiord-blue;
  padding: 8px 24px;
  background: $white;
}
.flexContainer {
  display: flex;
  align-items: center;
  height: 54px;
}
.assignContainer {
  height: 60px;
  display: flex;
  justify-content: center;
}
.red {
  color: $persimmon-red;
  font-size: 14px;
  text-align: center;
}
.green {
  color: $malachite-green;
  font-size: 14px;
  text-align: center;
}
.display {
  display: block;
}
.hide {
  display: none;
}
.msgWrapContainer {
  height: 420px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 4px;

  & > :first-child {
    margin-top: auto;
  }
}
.bottomScrollElem {
  height: 0;
}
.conversationStarterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-left: 24px;
  padding-right: 24px;
  gap: 4px;
}
.conversationItem {
  word-break: break-word;
  display: flex;
}
.actionButton {
  outline: none;
  border: none;
  text-align: left;
  font-size: 14px;
  display: block;
  padding: 8px 16px;
  cursor: pointer;
  color: #101D46;
  background: white;
  line-height: 14px;
}

.actionButton:hover {
  background: var(--hoverBg) !important;
}